import { useState, useEffect } from "react";
import "./App.css";
import AdminLTE, { Sidebar } from "adminlte-2-react";
import Home from "./components/Home";
import Questions from "./components/Questions";
import QuestionFlow from "./components/QuestionFlow";
import $ from "jquery";
import logo from "./assets/logo.png"; // Tell Webpack this JS file uses this image
import ListOfWines from "./components/ListOfWines";
import Dashboard from "./components/Dashboard";
import axios from "axios";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Users from "./components/Users";
import { getData } from "./components/Communication";

//allow session cookies to be sent with axios
axios.defaults.withCredentials = true;

const apiUrl = process.env.REACT_APP_API_URL;
const designerUrl = process.env.REACT_APP_DESIGNER_URL;

function App() {
  const [items, setItems] = useState([]);
  const { Item, Header, UserPanel, Searchbar } = Sidebar;
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [appInformation, setAppInformation] = useState({});

  useEffect(() => {
    getData(process.env.REACT_APP_API_URL + "/status").then((data) => {
      setAppInformation(data.data);
    });
  }, []);

  useEffect(() => {
    console.log(appInformation);
  }, [appInformation]);

  const sidebar = [
    <Item key="Start" text="Start" to="/home" icon="fa-home" />,
    <Item
      key="Dashboard"
      text="Dashboard"
      to="/dashboard"
      icon="fa-chart-bar"
    />,
    <Item
      key="questions"
      text="Fragen & Antworten"
      to="/form"
      icon="fa-list"
    />,
    <Item key="wines" text="Weine" to="/ListOfWines" icon="fa-wine-bottle" />,
    <Item key="users" text="Benutzer" to="/users" icon="fa-users" />,
    <Item key="logout" text="Abmelden" to="/Logout" icon="fa-power-off" />,

    <div className="appInformation">
      {appInformation.version}
      <br />
      {appInformation.environment}
    </div>,
  ];
  const noSidebar = [];

  useEffect(() => {
    if (localStorage.getItem("loggedIn") == "1") {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    let currentPath = window.location.pathname.split("/")[1];
    console.log(isLoggedIn);
    if (isLoggedIn !== null) {
      if (!isLoggedIn && currentPath != "login") {
        window.location.href = "/login";
      }
      if (isLoggedIn && currentPath == "login") {
        window.location.href = "/home";
      }
    }
    if (!isLoggedIn) {
      document.body.classList.add("sidebar-collapse");
      $(".sidebar-toggle").hide();
      $(".main-header > .logo").hide();
    } else {
      document.body.classList.remove("sidebar-collapse");
      $(".sidebar-toggle").show();
      $(".main-header > .logo").show();
    }
  }, [isLoggedIn]);

  return (
    <div>
      {!isLoggedIn && (
        <AdminLTE
          title={[]}
          titleShort={["CoW", "WineFinder"]}
          sidebar={isLoggedIn == true ? sidebar : noSidebar}
          theme="black-light"
          homeTo="/"
          browserTitle="CoW - WineFinder"
        >
          <Login path="/" />
        </AdminLTE>
      )}
      {isLoggedIn && (
        <AdminLTE
          title={[]}
          titleShort={["CoW", "WineFinder"]}
          theme="black-light"
          sidebar={isLoggedIn == true ? sidebar : noSidebar}
          homeTo="/home"
          browserTitle="CoW - WineFinder"
        >
          <Home path="/home" />
          <Questions path="/form" />
          <ListOfWines path="/ListOfWines" />
          <Dashboard path="/dashboard" />
          <Users path="/users" />
          <Logout path="/Logout" />
        </AdminLTE>
      )}
    </div>
  );
}

export default App;
