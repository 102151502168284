import React, { useEffect, useState, useRef } from "react";
import { Content, Row, Col, Inputs, Button } from "adminlte-2-react";
import { deleteData, getData, postData, putData } from "./Communication";
import $ from "jquery";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Questions() {
  const [items, setItems] = useState({ pages: [] });
  const [questions, setQuestions] = useState([]);
  const [nextQuestions, setNextQuestions] = useState([]);
  const [options, setOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [optionAttr, setOptionAttr] = useState({});
  const [questionAttr, setQuestionAttr] = useState({});
  const isCalledRef = useRef(false);
  const [activeClass, setActiveClass] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 120);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [currentOptionId, setCurrentOptionId] = useState(null);
  const [foundError, setFoundError] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [filterModalIsOpen, setFilterModalIsOpen] = React.useState(false);
  const [exportModalIsOpen, setExportModalIsOpen] = React.useState(false);

  const notify = () =>
    toast("Einstellungen gespeichert", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });

  const [hideIfAtLeastOneAnswerExists, setHideIfAtLeastOneAnswerExists] =
    useState([]);
  const [hideIfNotAllAnswersExists, setHideIfNotAllAnswersExists] = useState(
    []
  );
  const [showIfAtLeastOneAnswerExists, setShowIfAtLeastOneAnswerExists] =
    useState([]);
  const [showIfAllAnswersExists, setShowIfAllAnswersExists] = useState([]);

  const { Text, Checkbox, Radio, Select } = Inputs;
  const [priceOperator, setPriceOperator] = useState([
    "Kein Preisfilter",
    "<",
    "<=",
    "=",
    ">=",
    ">",
  ]);
  const [priceAttributes, setPriceAttributes] = useState({
    field: "price",
    operator: "Kein Preisfilter",
    value: "",
  });
  const addQuestions = (object) => {
    setQuestions((prevNodes) => [...prevNodes, object]);
  };

  const addNextQuestions = (object) => {
    setNextQuestions((prevNodes) => [...prevNodes, object]);
  };

  const addOption = (object) => {
    setOptions((prevNodes) => [...prevNodes, object]);
  };

  React.useEffect(() => {
    console.log("nextQuestions", nextQuestions);
  }, [nextQuestions]);

  const loadQuestions = () => {
    getData(process.env.REACT_APP_API_URL + "/designer/graph")
      .then((res) => {
        console.log("GET: DESIGNER/GRAPH", res);
        setQuestions([]);
        setNextQuestions([]);
        addNextQuestions({ id: null, text: "Nein", value: "null" });
        setAllOptions(res.data.edges);
        res.data.nodes.map((node) => {
          addQuestions({
            id: node.id,
            text: node.text,
            value: node.id,
            internalLabel: node.internalLabel,
          });
          addNextQuestions({ id: node.id, text: node.text, value: node.id });
          if (
            res.data.edges.filter((item) => item.question == node.id).length ==
            0
          ) {
            setFoundError(true);
          }
        });
        isCalledRef.current = true;
      })
      .catch((error) => {});
  };

  const loadQuestionAttributes = (id) => {
    if (id != currentQuestionId) {
      setCurrentQuestionId(id);
      setCurrentOptionId(null);
    }

    getData(process.env.REACT_APP_API_URL + "/designer/graph").then((res) => {
      console.log("GET: DESIGNER/GRAPH", res);
      //setOptions([]);
      res.data.nodes
        .filter((item) => item.id === id)
        .map((option) => {
          console.log("QUESTION ATTR", option);
          setQuestionAttr(option);
          $("select[name=questionNextQuestion]").val(option.skipToQuestion);
        });
    });
  };

  const loadOptions = (id) => {
    loadQuestionAttributes(id);

    getData(process.env.REACT_APP_API_URL + "/designer/graph").then((res) => {
      console.log("loadoptions(" + id + ") GET: DESIGNER/GRAPH", res);

      setOptions([]);
      let filtersExist = 0;
      res.data.edges
        .filter((item) => item.question === id)
        .map((option) => {
          filtersExist =
            option?.filters?.hideIfAtLeastOneAnswerExists?.length ?? 0;
          if (!filtersExist)
            filtersExist =
              option?.filters?.hideIfNotAllAnswersExists?.length ?? 0;
          if (!filtersExist)
            filtersExist =
              option?.filters?.showIfAtLeastOneAnswerExists?.length ?? 0;
          if (!filtersExist)
            filtersExist = option?.filters?.showIfAllAnswersExists?.length ?? 0;
          addOption({
            id: option.id,
            text: option.text,
            count: option?.attributes?.length ?? 0,
            filter: filtersExist ?? 0,
          });
        });
    });
  };

  const loadOptionAttributes = (id) => {
    setCurrentOptionId(id);
    getData(process.env.REACT_APP_API_URL + "/designer/graph").then((res) => {
      console.log("GET: DESIGNER/GRAPH", res);

      res.data.edges
        .filter((item) => item.id === id)
        .map((option) => {
          console.log("OPTION ATTR", option);
          if (
            option.valueFilters == null ||
            option.valueFilters.length == 0 ||
            option.valueFilters[0].operator == "Kein Preisfilter"
          ) {
            setPriceAttributes({
              field: "price",
              operator: "Kein Preisfilter",
              value: "",
            });
          } else {
            setPriceAttributes(option.valueFilters[0]);
          }

          setOptionAttr(option);
          setSelectedAttributes(option.attributes);
          option.nextQuestion == null
            ? (option.nextQuestion = "null")
            : (option.nextQuestion = option.nextQuestion);
          $("select[name=optionNextQuestion]").val(option.nextQuestion);

          option.filters = option.filters ? option.filters : [];

          let hideAny = option.filters.hideIfAtLeastOneAnswerExists
            ? option.filters.hideIfAtLeastOneAnswerExists
            : [];
          let hideIfNotAll = option.filters.hideIfNotAllAnswersExists
            ? option.filters.hideIfNotAllAnswersExists
            : [];
          let showIfOne = option.filters.showIfAtLeastOneAnswerExists
            ? option.filters.showIfAtLeastOneAnswerExists
            : [];
          let showIfAll = option.filters.showIfAllAnswersExists
            ? option.filters.showIfAllAnswersExists
            : [];
          setHideIfAtLeastOneAnswerExists(hideAny);
          setHideIfNotAllAnswersExists(hideIfNotAll);
          setShowIfAllAnswersExists(showIfAll);
          setShowIfAtLeastOneAnswerExists(showIfOne);
          console.log("hideAny", hideAny);
          console.log("hideIfNotAll", hideIfNotAll);
          console.log("showIfOne", showIfOne);
          console.log("showIfAll", showIfAll);
        });
      console.log("attr-Daten bei loadOption", selectedAttributes, attributes);
    });
  };

  const importData = async () => {
    var formData = new FormData();
    formData.append("backup", $("#backup").prop("files")[0]);
    console.log(formData);

    $.ajax({
      url: process.env.REACT_APP_API_URL + "/designer/import",
      data: formData,
      cache: false,
      xhrFields: {
        withCredentials: true,
      },
      contentType: false,
      processData: false,
      method: "POST",
      success: function (data) {
        let msgStr =
          "Importierte Fragen: " +
          data.questions +
          ", importierte Antworten: " +
          data.answers +
          ", importierte Attribute zu Antworten: " +
          data.answersToAttributes;
        alert(msgStr);
        setExportModalIsOpen(false);
      },
    });
  };

  const exportData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/designer/export",
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await response.blob();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        new Date().getFullYear() +
          "" +
          (new Date().getMonth() + 1) +
          "" +
          new Date().getDate() +
          "_winefinder_export.zip"
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      alert("Es ist ein Fehler aufgetreten");
      console.error("Fehler beim Herunterladen der Datei:", error);
    }
  };

  const addQuestion = () => {
    let data = {
      text: "Neue Frage",
      displayType: "button",
      autoSubmit: true,
      isStart: false,
      showResults: false,
    };
    postData(process.env.REACT_APP_API_URL + "/designer/questions", data).then(
      (res) => {
        if (res.status == 200) {
          loadQuestions();
          notify();
        }
      }
    );
  };

  const editQuestion = () => {
    let data = {
      text: questionAttr.text,
      displayType: questionAttr.displayType,
      autoSubmit: true,
      isStart: questionAttr.isStart,
      showResults: questionAttr.showResults,
      showResultsButton: questionAttr.showResultsButton,
      internalLabel: questionAttr.internalLabel,
      skipToQuestion:
        questionAttr.skipToQuestion == "Nein" ||
        questionAttr.skipToQuestion == "null"
          ? null
          : questionAttr.skipToQuestion,
    };
    console.log("PUT QUESTION", data);
    putData(
      process.env.REACT_APP_API_URL +
        "/designer/questions/" +
        currentQuestionId,
      data
    ).then((res) => {
      if (res.status == 200) {
        loadQuestions();
        notify();
      }
    });
  };

  //TODO
  const editOption = () => {
    let data = {
      text: optionAttr.text,
      image: optionAttr.image,
      question: currentQuestionId,
      hideLabel: optionAttr.hideLabel ? true : false,
      unClickable: optionAttr.unClickable ? true : false,
      invertWeight: optionAttr.invertWeight ? true : false,
      index: optionAttr.index,
      valueFilters:
        priceAttributes.operator == "Kein Preisfilter" ? [] : [priceAttributes],

      nextQuestion:
        optionAttr.nextQuestion == "Nein" || optionAttr.nextQuestion == "null"
          ? null
          : optionAttr.nextQuestion,
      attributes:
        selectedAttributes.length > 0
          ? selectedAttributes.map((element) => element.id)
          : [],
      filters: {
        hideIfAtLeastOneAnswerExists: hideIfAtLeastOneAnswerExists,
        hideIfNotAllAnswersExists: hideIfNotAllAnswersExists,
        showIfAtLeastOneAnswerExists: showIfAtLeastOneAnswerExists,
        showIfAllAnswersExists: showIfAllAnswersExists,
      },
    };
    console.log("DATA", data);
    putData(
      process.env.REACT_APP_API_URL + "/designer/answers/" + currentOptionId,
      data
    ).then((res) => {
      if (res.status == 200) {
        notify();
        loadQuestions();
        loadOptions(currentQuestionId);
      }
    });
  };

  const deleteQuestion = () => {
    if (window.confirm("Soll die Frage wirklich gelöscht werden?")) {
      deleteData(
        process.env.REACT_APP_API_URL +
          "/designer/questions/" +
          currentQuestionId
      ).then((res) => {
        loadQuestions();
      });
    }
  };

  const deleteOption = () => {
    if (window.confirm("Soll die Option wirklich gelöscht werden?")) {
      deleteData(
        process.env.REACT_APP_API_URL + "/designer/answers/" + currentOptionId
      ).then((res) => {
        loadOptions(currentQuestionId);
      });
    }
  };

  const postOption = () => {
    let data = {
      text: "Neue Antwort",
      image: "",
      question: currentQuestionId,
      attributes: [],
      nextQuestion: null,
      filters: {
        hideIfAtLeastOneAnswerExists: [],
        hideIfNotAllAnswersExists: [],
        showIfAtLeastOneAnswerExists: [],
        showIfAllAnswersExists: [],
      },
    };
    postData(process.env.REACT_APP_API_URL + "/designer/answers", data).then(
      (res) => {
        console.log(res);
        if (res.status == 200) {
          loadOptions(currentQuestionId);
          notify();
        }
      }
    );
  };

  const loadAllAttributes = () => {
    getData(process.env.REACT_APP_API_URL + "/designer/attributes").then(
      (res) => {
        console.log("ALL ATTRIBUTES ", res.data);
        setAttributes(res.data);
      }
    );
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      loadQuestions();
      loadAllAttributes();
    }
    $(".questionCol").css("height", windowHeight);
  }, []);

  useEffect(() => {
    console.log("selectedAttributes", selectedAttributes);
  }, [selectedAttributes]);

  useEffect(() => {
    console.log("priceAttributes", priceAttributes);
  }, [priceAttributes]);

  useEffect(() => {
    console.log("questionAttr", questionAttr);
  }, [questionAttr]);

  useEffect(() => {
    console.log("hideIfNotAllAnswersExists", hideIfNotAllAnswersExists);
  }, [hideIfNotAllAnswersExists]);

  useEffect(() => {
    if (currentQuestionId != null) {
      $(".questionItem").removeClass("questionItemActive");
      $("#q" + currentQuestionId).addClass("questionItemActive");
    }
  }, [currentQuestionId]);

  useEffect(() => {
    if (currentQuestionId != null) {
      $(".optionItem").removeClass("optionItemActive");
      $("#o" + currentOptionId).addClass("optionItemActive");
    }
  }, [currentOptionId]);

  return (
    <Content title="" subTitle="">
      {foundError && (
        <Row
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            marginBottom: "15px",
          }}
        >
          <Col xs="12" className="errorLine">
            Bitte beachte, dass mindestens eine Frage keine Antworten besitzt!
          </Col>
        </Row>
      )}
      <Row>
        <Col xs="4" className="questionCol">
          <div style={{ paddingBottom: "5px" }}>
            <h4>
              Fragen
              <button
                className="btn btn-default btn-sm pull-right"
                onClick={() => addQuestion()}
              >
                +
              </button>
            </h4>
          </div>
          {questions.map((item, index) => {
            return (
              <div
                key={"q" + index}
                id={"q" + item.id}
                onClick={() => loadOptions(item.id)}
                className="questionItem"
              >
                {item.internalLabel.substring(0, 5)} {item.text}
              </div>
            );
          })}
        </Col>
        <Col xs="4" className="questionCol">
          <div style={{ paddingBottom: "5px" }}>
            <h4>
              Optionen
              <button
                className="btn btn-default btn-sm pull-right"
                onClick={() => postOption()}
              >
                +
              </button>
            </h4>
          </div>
          {options.map((item, index) => {
            console.log(item);
            return (
              <div
                key={"o" + index}
                id={"o" + item.id}
                onClick={() => loadOptionAttributes(item.id)}
                className="optionItem"
              >
                {item.text}
                {item.filter > 0 ? <div className="filter"></div> : ""}
                <span
                  className={
                    "pull-right " +
                    (item.count == 0
                      ? "attributeCounterWarning"
                      : "attributeCounter")
                  }
                >
                  {item.count}
                </span>
              </div>
            );
          })}
        </Col>
        <Col xs="4" className="attributeCol">
          <div
            class="row"
            style={{
              paddingBottom: "5px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <h4>
              Eigenschaften{" "}
              <Button
                className="btn btn-sm btn-default pull-right"
                id="wfExportBtn"
                onClick={() => setExportModalIsOpen(true)}
                style={{ textAlign: "center", marginBottom: "5px !important" }}
                text="Im- & Export"
              />
            </h4>
          </div>
          {questionAttr.id != undefined && (
            <div className="questionAttrContainer">
              <Col xs="12" className="bgContainer">
                <Text
                  name="wfQuestionText"
                  placeholder=""
                  label="Frage"
                  labelSm="4"
                  sm="8"
                  labelPosition="left"
                  value={questionAttr.text}
                  onChange={(e) => {
                    const { value } = e.target;
                    setQuestionAttr((prev) => ({
                      ...prev,
                      text: value,
                    }));
                  }}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Text
                  name="wfIntText"
                  placeholder=""
                  label="Int. Bez."
                  labelSm="4"
                  sm="8"
                  labelPosition="left"
                  value={questionAttr.internalLabel}
                  onChange={(e) => {
                    const { value } = e.target;
                    setQuestionAttr((prev) => ({
                      ...prev,
                      internalLabel: value,
                    }));
                  }}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Select
                  label="Typ"
                  labelSm="4"
                  sm="8"
                  options={[
                    { text: "Button", value: "button" },
                    { text: "Slider", value: "slider" },
                    { text: "Mehrfachauswahl", value: "grid" },
                    { text: "Autocomplete", value: "autocomplete" },
                  ]}
                  value={questionAttr.displayType}
                  onChange={(e) => {
                    const value = e.target.value;
                    setQuestionAttr((prev) => ({
                      ...prev,
                      displayType: value,
                    }));
                  }}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Checkbox
                  label="Ergebnisse autom. einblenden"
                  value={true}
                  labelSm="10"
                  sm="2"
                  checked={questionAttr.showResults}
                  onChange={(e) => {
                    const value = e.target.checked;
                    setQuestionAttr((prev) => ({
                      ...prev,
                      showResults: value,
                    }));
                  }}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Checkbox
                  label="Ergebnisse anzeigbar"
                  value={true}
                  labelSm="10"
                  sm="2"
                  checked={questionAttr.showResultsButton}
                  onChange={(e) => {
                    const value = e.target.checked;
                    setQuestionAttr((prev) => ({
                      ...prev,
                      showResultsButton: value,
                    }));
                  }}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Checkbox
                  label="Ist Start?"
                  value={1}
                  labelSm="10"
                  sm="2"
                  checked={questionAttr.isStart}
                  onChange={(e) => {
                    const value = e.target.checked;
                    setQuestionAttr((prev) => ({
                      ...prev,
                      isStart: value,
                    }));
                  }}
                />
              </Col>

              <Col xs="12" className="bgContainer">
                <Text
                  name="exampleInputskippableLabel"
                  placeholder=""
                  label="Label ohne Präferenz"
                  labelSm="4"
                  sm="8"
                  labelPosition="left"
                  value={questionAttr.skippableLabel}
                  onChange={(e) => {
                    const { value } = e.target;
                    setQuestionAttr((prev) => ({
                      ...prev,
                      skippableLabel: value,
                    }));
                  }}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Select
                  label="Überspringbar"
                  name="questionNextQuestion"
                  labelSm="4"
                  sm="8"
                  options={nextQuestions}
                  value={questionAttr.skipToQuestion}
                  onChange={(e) => {
                    setQuestionAttr((prev) => ({
                      ...prev,
                      skipToQuestion: e.target.value,
                    }));
                  }}
                />
              </Col>
              <Col xs="6" className="bgContainer">
                <Button
                  text="Löschen"
                  className="pull-left btn btn-sm btn-default saveBtn"
                  onClick={() => deleteQuestion()}
                />
              </Col>
              <Col xs="6" className="bgContainer">
                <Button
                  text="Speichern"
                  className="pull-right btn btn-sm btn-default saveBtn"
                  onClick={() => editQuestion()}
                />
              </Col>
            </div>
          )}
          {optionAttr.id != undefined && currentOptionId != null && (
            <div className="questionAttrContainer" style={{ height: "540px" }}>
              <Col xs="12" className="bgContainer">
                <Text
                  labelSm="4"
                  sm="8"
                  name="exampleInputEmail1"
                  placeholder=""
                  label="Antwort"
                  labelPosition="left"
                  value={optionAttr.text}
                  onChange={(e) => {
                    setOptionAttr((prev) => ({
                      ...prev,
                      text: e.target.value,
                    }));
                  }}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Text
                  labelSm="4"
                  sm="8"
                  name="exampleInputEmail1"
                  placeholder=""
                  label="Reihenfolge"
                  labelPosition="left"
                  value={optionAttr.index}
                  onChange={(e) => {
                    setOptionAttr((prev) => ({
                      ...prev,
                      index: e.target.value,
                    }));
                  }}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Text
                  labelSm="4"
                  sm="8"
                  name="EdgeAttrUrl"
                  placeholder="https:///..."
                  label="Bild URL"
                  labelPosition="left"
                  value={optionAttr.image}
                  onChange={(e) => {
                    setOptionAttr((prev) => ({
                      ...prev,
                      image: e.target.value,
                    }));
                  }}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Select
                  label="Nächste Frage"
                  options={nextQuestions}
                  labelSm="4"
                  sm="8"
                  name="optionNextQuestion"
                  id="optionNextQuestion"
                  value={optionAttr.nextQuestion}
                  onChange={(e) => {
                    setOptionAttr((prev) => ({
                      ...prev,
                      nextQuestion: e.target.value,
                    }));
                  }}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Checkbox
                  label="Label ausblenden"
                  labelPosition="left"
                  labelSm="10"
                  sm="2"
                  checked={optionAttr.hideLabel ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked;
                    setOptionAttr((prev) => ({
                      ...prev,
                      hideLabel: value,
                    }));
                  }}
                  value={true}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Checkbox
                  label="Nicht klickbar"
                  labelPosition="left"
                  labelSm="10"
                  sm="2"
                  checked={optionAttr.unClickable ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked;
                    setOptionAttr((prev) => ({
                      ...prev,
                      unClickable: value,
                    }));
                  }}
                  value={true}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Checkbox
                  label="Ausschluss (Negatives Gewicht)"
                  labelPosition="left"
                  labelSm="10"
                  sm="2"
                  checked={optionAttr.invertWeight ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked;
                    setOptionAttr((prev) => ({
                      ...prev,
                      invertWeight: value,
                    }));
                  }}
                  value={true}
                />
              </Col>
              <Col xs="12" className="bgContainer">
                <Select
                  label="Preisoperator"
                  options={priceOperator}
                  labelSm="4"
                  sm="8"
                  value={priceAttributes.operator}
                  onChange={(e) => {
                    setPriceAttributes((prev) => ({
                      ...prev,
                      operator: e.target.value,
                    }));
                  }}
                />
              </Col>
              {priceAttributes.operator != "Kein Preisfilter" && (
                <Col xs="12" className="bgContainer">
                  <Text
                    labelSm="4"
                    sm="8"
                    name="priceValue"
                    placeholder=""
                    label="...Wert"
                    labelPosition="left"
                    value={priceAttributes.value}
                    onChange={(e) => {
                      setPriceAttributes((prev) => ({
                        ...prev,
                        value: Number(e.target.value.replace(",", ".")),
                      }));
                    }}
                  />
                </Col>
              )}
              <Col xs={12} className="scrollList">
                {attributes !== undefined && selectedAttributes !== undefined
                  ? attributes.map((item) => {
                      return (
                        <Checkbox
                          label={item.name}
                          labelSm={10}
                          sm={2}
                          checked={
                            selectedAttributes.some(
                              (element) => element.id === item.id
                            )
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            if (
                              selectedAttributes.some(
                                (element) => element.id === item.id
                              ) === false
                            ) {
                              let newRow = { id: e.target.value };
                              setSelectedAttributes((prev) => [
                                ...prev,
                                newRow,
                              ]);
                            } else {
                              const updatedWerte = selectedAttributes.filter(
                                (item) => item.id !== e.target.value
                              );
                              setSelectedAttributes(updatedWerte);
                            }
                          }}
                          value={item.id}
                        />
                      );
                    })
                  : ""}
              </Col>
              <Col xs="4" className="bgContainer"></Col>
              <Col
                xs={8}
                className="bgContainer"
                style={{ fontWeight: "bold" }}
              >
                <div onClick={() => setFilterModalIsOpen(true)}>
                  Filter anlegen...
                </div>
              </Col>
              <Col xs="6" className="bgContainer">
                <Button
                  text="Löschen"
                  className="pull-left btn btn-sm btn-default saveBtn"
                  onClick={() => deleteOption()}
                />
              </Col>
              <Col xs="6" className="bgContainer">
                <Button
                  text="Speichern"
                  className="pull-right btn btn-sm btn-default saveBtn"
                  onClick={() => editOption()}
                />
              </Col>
            </div>
          )}
        </Col>
      </Row>
      <Modal
        isOpen={filterModalIsOpen}
        onRequestClose={() => setFilterModalIsOpen(false)}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            width: "50%",
            height: "80%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        contentLabel="Example Modal"
      >
        <h3>Filter</h3>

        <Row>
          <Col
            xs={12}
            className="attributeCol bgContainer"
            style={{
              margin: "10px",
              width: "95%",
            }}
          >
            <Col xs={2}>Option</Col>
            <Col xs={10}>
              <b>{optionAttr.text}</b>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <b>
              Ausblenden wenn nicht <u>alle</u> der nachfolgenden Optionen im
              Verlauf gewählt wurden
            </b>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className="attributeCol bgContainer"
            style={{
              margin: "10px",
              width: "95%",
              maxHeight: "140px",
              overflowY: "auto",
              padding: "0px",
            }}
          >
            {questions.map((q) => {
              return (
                <Col xs={12} className="bgContainer">
                  {allOptions
                    .filter((i) => i.question === q.id)
                    .map((o) => {
                      return (
                        <Col xs={12} className="bgContainer">
                          <Checkbox
                            label={q.text + " - " + o.text}
                            labelPosition="left"
                            labelSm="11"
                            sm="1"
                            checked={
                              hideIfNotAllAnswersExists !== undefined &&
                              hideIfNotAllAnswersExists.some(
                                (item) => item === o.id
                              )
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              if (
                                !hideIfNotAllAnswersExists.some(
                                  (item) => item == o.id
                                )
                              ) {
                                setHideIfNotAllAnswersExists((prev) => [
                                  ...prev,
                                  e.target.value,
                                ]);
                              } else {
                                let filter =
                                  setHideIfNotAllAnswersExists.filter(
                                    (item) => item != e.target.value
                                  );
                                setHideIfNotAllAnswersExists(filter);
                              }
                            }}
                            value={o.id}
                          />
                        </Col>
                      );
                    })}
                </Col>
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <b>
              Ausblenden wenn mindestens <u>eine</u> der nachfolgenden Optionen
              im Verlauf gewählt wurde
            </b>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className="attributeCol bgContainer"
            style={{
              margin: "10px",
              width: "95%",
              maxHeight: "140px",
              overflowY: "auto",
              padding: "0px",
            }}
          >
            {questions.map((q) => {
              return (
                <Col xs={12} className="bgContainer">
                  {allOptions
                    .filter((i) => i.question === q.id)
                    .map((o) => {
                      return (
                        <Col xs={12} className="bgContainer">
                          <Checkbox
                            label={q.text + " - " + o.text}
                            labelPosition="left"
                            labelSm="11"
                            sm="1"
                            checked={
                              hideIfAtLeastOneAnswerExists !== undefined &&
                              hideIfAtLeastOneAnswerExists.some(
                                (item) => item === o.id
                              )
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              if (
                                !hideIfAtLeastOneAnswerExists.some(
                                  (item) => item == o.id
                                )
                              ) {
                                setHideIfAtLeastOneAnswerExists((prev) => [
                                  ...prev,
                                  e.target.value,
                                ]);
                              } else {
                                let filter =
                                  hideIfAtLeastOneAnswerExists.filter(
                                    (item) => item != e.target.value
                                  );
                                setHideIfAtLeastOneAnswerExists(filter);
                              }
                            }}
                            value={o.id}
                          />
                        </Col>
                      );
                    })}
                </Col>
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <b>
              Anzeigen wenn <u>alle</u> der nachfolgenden Optionen im Verlauf
              gewählt wurden
            </b>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className="attributeCol bgContainer"
            style={{
              margin: "10px",
              width: "95%",
              maxHeight: "140px",
              overflowY: "auto",
              padding: "0px",
            }}
          >
            {questions.map((q) => {
              return (
                <Col xs={12} className="bgContainer">
                  {allOptions
                    .filter((i) => i.question === q.id)
                    .map((o) => {
                      return (
                        <Col xs={12} className="bgContainer">
                          <Checkbox
                            label={q.text + " - " + o.text}
                            labelPosition="left"
                            labelSm="11"
                            sm="1"
                            checked={
                              showIfAllAnswersExists !== undefined &&
                              showIfAllAnswersExists.some(
                                (item) => item === o.id
                              )
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              if (
                                showIfAllAnswersExists !== undefined &&
                                !showIfAllAnswersExists.some(
                                  (item) => item == o.id
                                )
                              ) {
                                setShowIfAllAnswersExists((prev) => [
                                  ...prev,
                                  e.target.value,
                                ]);
                              } else {
                                let filter = showIfAllAnswersExists.filter(
                                  (item) => item != e.target.value
                                );
                                setShowIfAllAnswersExists(filter);
                              }
                            }}
                            value={o.id}
                          />
                        </Col>
                      );
                    })}
                </Col>
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <b>
              Anzeigen wenn mindestens <u>eine</u> der nachfolgenden Optionen im
              Verlauf gewählt wurde
            </b>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className="attributeCol bgContainer"
            style={{
              margin: "10px",
              width: "95%",
              maxHeight: "140px",
              overflowY: "auto",
              padding: "0px",
            }}
          >
            {questions.map((q) => {
              return (
                <Col xs={12} className="bgContainer">
                  {allOptions
                    .filter((i) => i.question === q.id)
                    .map((o) => {
                      return (
                        <Col xs={12} className="bgContainer">
                          <Checkbox
                            label={q.text + " - " + o.text}
                            labelPosition="left"
                            labelSm="11"
                            sm="1"
                            checked={
                              showIfAtLeastOneAnswerExists !== undefined &&
                              showIfAtLeastOneAnswerExists.some(
                                (item) => item === o.id
                              )
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              if (
                                showIfAtLeastOneAnswerExists !== undefined &&
                                !showIfAtLeastOneAnswerExists.some(
                                  (item) => item == o.id
                                )
                              ) {
                                setShowIfAtLeastOneAnswerExists((prev) => [
                                  ...prev,
                                  e.target.value,
                                ]);
                              } else {
                                let filter =
                                  showIfAtLeastOneAnswerExists.filter(
                                    (item) => item != e.target.value
                                  );
                                setShowIfAtLeastOneAnswerExists(filter);
                              }
                            }}
                            value={o.id}
                          />
                        </Col>
                      );
                    })}
                </Col>
              );
            })}
          </Col>
        </Row>
        <button
          className="btn btn-default pull-right"
          onClick={() => setFilterModalIsOpen(false)}
        >
          Schließen
        </button>
      </Modal>
      <Modal
        isOpen={exportModalIsOpen}
        onRequestClose={() => setFilterModalIsOpen(false)}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            width: "50%",
            height: "40%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        contentLabel="Example Modal"
      >
        <h3>Daten Im- und Exportieren</h3>
        <Button
          className="btn btn-sm btn-default "
          onClick={() => exportData()}
          text="Daten exportieren"
        />
        <hr />
        <form
          method="post"
          enctype="multipart/form-data"
          id="backupForm"
          name="backupForm"
        >
          <Row>
            <Col sm={12}>
              <label>
                Um Daten zu importieren, wähle bitte eine entsprechende
                Archivdatei aus:
                <input
                  name="backup"
                  id="backup"
                  className="btn btn-sm "
                  type="file"
                  size="100"
                  accept=".zip"
                />
              </label>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <input
                type="button"
                className="btn btn-sm btn-default"
                value="Verarbeiten"
                onClick={() => importData()}
              />
              <input
                type="button"
                className="btn btn-sm btn-default pull-right"
                value="Schließen"
                onClick={() => setExportModalIsOpen(false)}
              />
            </Col>
          </Row>
        </form>
      </Modal>
      <ToastContainer />
    </Content>
  );
}

export default Questions;
