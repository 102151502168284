import axios from "axios";

const debug = true;

function routeByHttpCode(code) {
  if (code == "401") {
    if (debug) console.log("Automatic logout by 401");
    window.location.href = "/Logout";
  }
}

export async function getData(url) {
  try {
    const response = await axios.get(url);
    if (debug) console.log("THEN", url, response);
    return response;
  } catch (error) {
    if (debug) console.log("ERROR", url, error);
    routeByHttpCode(error.response.status);
    throw new Error(error);
  }
}

export async function postData(url, data) {
  try {
    const response = await axios.post(url, data);
    if (debug) console.log("THEN", url, response);
    return response;
  } catch (error) {
    if (debug) console.log("ERROR", url, error);
    routeByHttpCode(error.response.status);
    throw new Error(error);
  }
}

export async function putData(url, data) {
  try {
    const response = await axios.put(url, data);
    if (debug) console.log("PUT success", url, response);
    return response;
  } catch (error) {
    if (debug) console.log("PUT error", url, error);
    routeByHttpCode(error.response.status);
    throw new Error(error);
  }
}

export async function deleteData(url) {
  try {
    const response = await axios.delete(url);
    if (debug) console.log("THEN", url, response);
    return response;
  } catch (error) {
    if (debug) console.log("ERROR", url, error);
    routeByHttpCode(error.response.status);
    throw new Error(error);
  }
}
