(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react-dom"), require("moment"), require("@fortawesome/react-fontawesome"), require("react-ionicons"), require("@fortawesome/fontawesome-svg-core"), require("@fortawesome/free-solid-svg-icons"), require("@fortawesome/free-brands-svg-icons"), require("@fortawesome/free-regular-svg-icons"), require("source-sans-pro/source-sans-pro.css"), require("fullcalendar"));
	else if(typeof define === 'function' && define.amd)
		define(["react-dom", "moment", "@fortawesome/react-fontawesome", "react-ionicons", "@fortawesome/fontawesome-svg-core", "@fortawesome/free-solid-svg-icons", "@fortawesome/free-brands-svg-icons", "@fortawesome/free-regular-svg-icons", "source-sans-pro/source-sans-pro.css", "fullcalendar"], factory);
	else if(typeof exports === 'object')
		exports["adminlte-2-react"] = factory(require("react-dom"), require("moment"), require("@fortawesome/react-fontawesome"), require("react-ionicons"), require("@fortawesome/fontawesome-svg-core"), require("@fortawesome/free-solid-svg-icons"), require("@fortawesome/free-brands-svg-icons"), require("@fortawesome/free-regular-svg-icons"), require("source-sans-pro/source-sans-pro.css"), require("fullcalendar"));
	else
		root["adminlte-2-react"] = factory(root["react-dom"], root["moment"], root["@fortawesome/react-fontawesome"], root["react-ionicons"], root["@fortawesome/fontawesome-svg-core"], root["@fortawesome/free-solid-svg-icons"], root["@fortawesome/free-brands-svg-icons"], root["@fortawesome/free-regular-svg-icons"], root["source-sans-pro/source-sans-pro.css"], root["fullcalendar"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__28__, __WEBPACK_EXTERNAL_MODULE__307__, __WEBPACK_EXTERNAL_MODULE__586__, __WEBPACK_EXTERNAL_MODULE__587__, __WEBPACK_EXTERNAL_MODULE__588__, __WEBPACK_EXTERNAL_MODULE__589__, __WEBPACK_EXTERNAL_MODULE__599__, __WEBPACK_EXTERNAL_MODULE__1037__) {
return 