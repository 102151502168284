import React, { useEffect, useState } from "react";
import { Content, Row, Col, Box, Button, SimpleTable } from "adminlte-2-react";

function Users() {
  const [items, setItems] = useState(null);
  const [tableData, setTableData] = useState([]);

  return (
    <Content title="Benutzer" subTitle="">
      <Row></Row>
    </Content>
  );
}

export default Users;
