import React, { useEffect, useState, useRef } from "react";
import {
  Content,
  Row,
  Badge,
  Col,
  Button,
  SimpleTable,
  Inputs,
} from "adminlte-2-react";
import { getData, putData } from "./Communication";
import $ from "jquery";

function ListOfWines() {
  const isCalledRef = useRef(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 120);
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [showInactiveItems, setShowInactiveItems] = useState(false);
  const [isBoosted, setIsBoosted] = useState(0);
  const [currentWineId, setCurrentWineId] = useState(null);

  const { Text, Checkbox, Radio, Select } = Inputs;

  const firstColumns = [
    { title: "Name", data: "name" },
    { title: "Preis", data: "price" },
    { title: "Aktiv", data: "active" },
    { title: "Status", data: "boost" },
  ];
  const attrTableFields = [
    { title: "Attribut", data: "attribute" },
    { title: "Faktor", data: "factor" },
  ];

  const addWine = (object) => {
    setData((prevNodes) => [...prevNodes, object]);
  };

  const addAttributes = (object) => {
    setAttributes((prevNodes) => [...prevNodes, object]);
  };

  const loadWines = async () => {
    setData([]);
    getData(process.env.REACT_APP_API_URL + "/designer/wines").then((res) => {
      console.log("WINES", res.data);
      setRawData(res.data);
      res.data
        .filter(
          showInactiveItems == false
            ? (item) => item.active == true
            : (item) => true == true
        )
        .map((item) => {
          let status = null;
          let boostBadge = null;
          if (item.active) {
            status = <Badge color={"success"} text={"Aktiv"} />;
          } else {
            status = <Badge color={"#dd4b39"} text={"Inaktiv"} />;
          }
          if (item.boost > 0) {
            boostBadge = <Badge color={"red"} text={"Boost"} />;
          } else {
            boostBadge = <div></div>;
          }
          addWine({
            id: item.id,
            name: item.name,
            price: (
              <div style={{ textAlign: "right" }}>
                {item.price.toFixed(2).toLocaleString("de-DE")}
              </div>
            ),
            active: status,
            boost: boostBadge,
          });
        });
    });
  };

  const loadWineAttributes = async (id) => {
    setCurrentWineId(id);
    rawData
      .filter((item) => item.id == id)
      .map((item) => {
        console.log("BOOST", item.boost);
        setIsBoosted(item.boost);
      });

    try {
      setAttributes([]);

      const attrResponse = await getData(
        `${process.env.REACT_APP_API_URL}/designer/attributes`
      );
      const wineResponse = await getData(
        `${process.env.REACT_APP_API_URL}/designer/wines`
      );

      console.log(wineResponse.data);

      wineResponse.data
        .filter((item) => item.id === id)
        .forEach((wine) => {
          wine.factors.forEach((factor) => {
            const attributItem = attrResponse.data.find(
              (attrFilter) => attrFilter.id === factor.attribute
            );

            if (attributItem) {
              console.log("Attr", attributItem);
              addAttributes({
                attribute: attributItem.name,
                factor: factor.factor,
              });
            }
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const editWine = () => {
    let data = {
      boost: isBoosted,
    };

    putData(
      process.env.REACT_APP_API_URL + "/designer/wines/" + currentWineId,
      data
    ).then((res) => {
      if (res.status == 200) {
        loadWines().then(() => {});
      }
    });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      //Laden nur einmal
      loadWines();
    }
    $(".questionCol").css("height", windowHeight);
  }, []);

  useEffect(() => {
    loadWines();
  }, [showInactiveItems]);

  useEffect(() => {
    if (currentWineId != null) {
      loadWineAttributes(currentWineId);
    }
  }, [rawData]);

  return (
    <Content
      title="Liste der Weine"
      subTitle="Änderungen bitte in PIM oder SAP vornehmen"
    >
      <Row>
        <Col sm={8}></Col>
        <Col sm={2} style={{ textAlign: "right" }}>
          <Checkbox
            label="Inaktive Weine einblenden"
            value={true}
            labelSm="10"
            sm="2"
            checked={showInactiveItems}
            onChange={(e) => {
              const value = e.target.checked;
              setShowInactiveItems(value);
            }}
          />
        </Col>
        <Col sm={2}>
          <Button
            className="btn btn-sm btn-default"
            style={{ textAlign: "center" }}
            text="Weine manuell laden"
          />
        </Col>
      </Row>
      <Row style={{ width: "100% !important", height: "100% !important" }}>
        <Col xs={9} className="questionCol">
          <SimpleTable
            columns={firstColumns}
            data={data}
            striped
            onSelect={(data) => loadWineAttributes(data.id)}
            hover
            responsive
          />
        </Col>
        <Col xs={3} className="questionCol">
          <Row>
            <SimpleTable columns={attrTableFields} data={attributes} striped />
          </Row>
          <Row>
            {currentWineId !== null && (
              <Col xs={12} className="questionAttrContainer bgContainer">
                <div style={{ paddingBottom: "5px" }}>
                  <h4>Optionen</h4>
                </div>
                <Checkbox
                  label="Boosten"
                  value={2}
                  labelSm="10"
                  sm="2"
                  checked={isBoosted}
                  onChange={(e) => {
                    let value = 0;
                    if (e.target.checked) {
                      value = 2;
                    }
                    setIsBoosted(e.target.checked);
                  }}
                />
                <Col xs="6"></Col>
                <Col xs="6" style={{ paddingTop: "20px" }}>
                  <Button
                    text="Speichern"
                    className="pull-right btn btn-sm btn-default saveBtn"
                    onClick={() => editWine()}
                  />
                </Col>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Content>
  );
}

export default ListOfWines;
