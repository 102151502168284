import React, { useEffect, useState, useRef } from "react";
import {
  Content,
  Row,
  Col,
  Box,
  Inputs,
  Button,
  Infobox,
  SimpleTable,
} from "adminlte-2-react";
import axios from "axios";
import $ from "jquery";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getData } from "./Communication";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "none",
    },
    title: {
      display: false,
      text: "",
    },
  },
};

function Dashboard() {
  const isCalledRef = useRef(false);
  const [activeClass, setActiveClass] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 120);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [labels, setLabels] = useState([]);
  const [abortIds, setAbortIds] = useState([]);
  const [wines, setWines] = useState([]);

  const { Text, Checkbox, Radio, Select } = Inputs;

  const attrRecWineList = [
    { title: "Artikelnummer", data: "articleNo" },
    { title: "Name", data: "name" },
    { title: "Häufigkeit", data: "count" },
  ];

  const addWine = (object) => {
    setWines((prevNodes) => [...prevNodes, object]);
  };

  const convertMilliseconds = (milliseconds) => {
    var s = milliseconds / 1000;
    var minuten = Math.floor(s / 60);
    var sekunden = Math.round(s - minuten * 60);
    if (minuten <= 9) {
      minuten = "0" + minuten;
    }
    if (sekunden <= 9) {
      sekunden = "0" + sekunden;
    }

    return minuten + ":" + sekunden;
  };

  const loadKpi = () => {
    getData(process.env.REACT_APP_API_URL + "/designer/kpis").then((res) => {
      console.log("GET: DESIGNER/KPIS", res);
      setRecommendations(res.data.recommendations);
    });
  };

  const data = {
    labels,
    datasets: [
      {
        label: "ABGEBROCHEN  BEI  FRAGE",
        data: abortIds,
        borderColor: "#cda58e",
        backgroundColor: "#cda58e",
        borderColor: "#cda58e",
      },
    ],
  };

  React.useEffect(() => {
    loadKpi();
  }, []);

  React.useEffect(() => {
    console.log("REC", recommendations);
    if (Object.keys(recommendations).length) {
      Object.keys(recommendations.wines).map((key, index) => {
        addWine({
          articleNo: recommendations.wines[key].id,
          name: recommendations.wines[key].name,
          count: recommendations.wines[key].count,
        });
      });
      getData(process.env.REACT_APP_API_URL + "/designer/graph").then((res) => {
        console.log("GET: DESIGNER/GRAPH", res);
        const items = [];
        const data = [];
        res.data.nodes.map((item) => {
          items.push(item.text);
          if (recommendations.abortedAtQuestion[item.id] != undefined) {
            data.push(recommendations.abortedAtQuestion[item.id]);
          } else {
            data.push(0);
          }
        });
        setLabels(items);
        setAbortIds(data);
      });
    }
  }, [recommendations]);

  return (
    <Content title="Dashboard" subTitle="">
      <Row style={{ paddingTop: "30px" }}>
        <Col xs="12">
          <h4>ABBRUCHVERHALTEN</h4>
        </Col>
        <Col xs="12" sm="1">
          <div className="kpiContainer">
            GESTARTET
            <br />
            <span>{recommendations.started}</span>
          </div>
          <div className="kpiContainer">
            BEENDET
            <br />
            <span>{recommendations.ended}</span>
          </div>
          <div className="kpiContainer">
            ABGEBROCHEN
            <br />
            <span>{recommendations.started - recommendations.ended}</span>
          </div>
        </Col>

        <Col xs="12" sm="11" style={{ paddingTop: 20 }}>
          <Line options={options} data={data} height={60} />
        </Col>
      </Row>
      <Row>
        <Col xs="12" style={{ paddingTop: "30px", paddingBottom: "10px" }}>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <h4>ZEITVERHALTEN</h4>
        </Col>
        <Col xs="4">
          <div className="kpiContainer">
            SCHNELLSTE
            <br />
            <span>
              {recommendations.durations
                ? convertMilliseconds(recommendations.durations.completedMin)
                : ""}
            </span>
          </div>
        </Col>
        <Col xs="4">
          <div className="kpiContainer">
            IM DURCHSCHNITT
            <br />
            <span>
              {recommendations.durations
                ? convertMilliseconds(recommendations.durations.completedAvg)
                : ""}
            </span>
          </div>
        </Col>
        <Col xs="4">
          <div className="kpiContainer">
            LANGSAMSTE
            <br />
            <span>
              {recommendations.durations
                ? convertMilliseconds(recommendations.durations.completedMax)
                : ""}
            </span>
          </div>
        </Col>
        <Row>
          <Col xs="12" style={{ paddingTop: "30px", paddingBottom: "10px" }}>
            <hr></hr>
          </Col>
        </Row>
        <Col xs="12">
          <h4>EMPFOHLENE WEINE</h4>
        </Col>
        <Col xs="12">
          {" "}
          <SimpleTable columns={attrRecWineList} data={wines} striped />
        </Col>
      </Row>
    </Content>
  );
}

export default Dashboard;
