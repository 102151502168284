import React, { useEffect } from "react";
import { Content, Row, Col, Inputs, Infobox, Box } from "adminlte-2-react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const designerUrl = process.env.REACT_APP_DESIGNER_URL;

function Login() {
  const { Text } = Inputs;
  const [email, setEmail] = React.useState("tom@tester.test");
  const [password, setPassword] = React.useState("");
  const query = new URLSearchParams(window.location.href.split("?")[1]);

  const sendLogin = () => {
    let data = {
      email: email,
      password: password,
    };
    if (email !== "" && password !== "") {
      axios
        .post(apiUrl + "/auth/login", data)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            localStorage.setItem("loggedIn", "1");
            window.location.href = "/home"; // TODO /home?
          } else {
            localStorage.setItem("loggedIn", "0");
            alert("Zugangsdaten falsch");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.setItem("loggedIn", "0");
            alert("Zugangsdaten falsch");
          }
        });
    } else {
      alert("Bitte gib deine Zugangsdaten ein");
    }
  };

  return (
    <Content title="" subTitle="" browserTitle="Anmeldung">
      {query.get("checkout") == "success" && (
        <Infobox
          icon="fa-check"
          color="green"
          number="Du kannst dich nun anmelden."
          text="Deine Buchung war erfolgreich"
        />
      )}
      {query.get("error_text") != "" && query.get("error_text") != null && (
        <Box title="Da ist etwas schief gelaufen..." type="danger">
          {query.get("error_text") +
            " Bitte wende dich an operations@vaionics.com"}
        </Box>
      )}
      <Row>
        <Col xs={12} md={12}>
          <div className="login-box">
            <div className="login-box-body">
              <div style={{ marginBottom: "20px" }}>
                <div
                  className="logo"
                  style={{ height: "80px", width: "350px" }}
                ></div>
              </div>

              <p className="login-box-msg">Bitte gib deine Zugangsdaten ein</p>
              <Text
                name="email"
                label="E-Mail"
                labelPosition="above"
                placeholder="email@email.com"
                iconRight="fas-envelope"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Text
                name="password"
                label="Passwort"
                inputType="password"
                labelPosition="above"
                placeholder="Password"
                iconRight="fas-lock"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <button
                type="submit"
                className="btn btn-cuw btn-block btn-flat"
                onClick={sendLogin}
              >
                Weiter
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default Login;
